import React, { Fragment } from "react";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { joyrideSteps } from "../../utils/constant";
import {
  GetAllGamesEndpoint,
  GetFirstDepthChartEndpoint,
  GetPlayByPlayInfoEndpoint,
} from "../../api/api";
// import { useMediaQuery } from "@material-ui/core";

const JoyrideComponent = ({ dispatch, ...props }) => {
  const { state } = props;
  // const isSmallScreen = useMediaQuery('(max-width:767px)');
  // const handleJoyrideCallback = (data) => {
  //   console.log("data: ", data);
  //   if (data.lifecycle === "start") {
  //   }

  //   if (data.lifecycle === "complete" || data.action === "close") {
  //     dispatch({ type: "TOUR_STOP" });
  // localStorage.setItem('joyrideSeen', 'true');
  // }

  // if(data.type === "step:after" && data.index === 0) {
  //   dispatch({ type: "TOUR_STOP"});
  // }else if(data.type === "step:after" && data.index === 0){
  //   dispatch({ type: "TOUR_STOP"});
  // }
  // const targetElement = document.querySelector(data.step.target);
  // if(data.type === "step:after" && data.index === 0) {
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }else

  // if(data.type === "step:after" && data.index === 1){
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("joyride-depth-chart")
  // ) {
  //   dispatch({
  //     type: "SET_NAVBAR_DROPDOWN_INDEX",
  //     inputVariables: {
  //       "input navbar dropdown index":
  //         state?.NavBarDropDownIndex === 0 ? 0 : 0,
  //     },
  //   });
  // this.setState({stepIndex:data.index + 1})
  // dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }
  // }else

  // if (data.action === "next" && data.type === "step:after") {
  // dispatch({
  //   type: "TOUR_START",
  //   inputVariables: { run: true, index: data?.index + 1 },
  // });
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("joyride-add-depth-chart")
  // ) {
  //   dispatch({
  //     type: "JOYRIDE_NAVIGATION",
  //     inputVariables: { routeName: "/add_depth_chart" },
  //   });
  // }
  // dispatch({ type: "ADD_DEPTH_CHART_TITLE" });
  // dispatch({
  //   type: "TOUR_START",
  //   inputVariables: { run: true, index: data?.index + 1 },
  // });
  // } else {
  // dispatch({
  //   type: "TOUR_START",
  //   inputVariables: { run: true, index: data?.index + 1 },
  // });
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("test-depth-chart-add-button")
  // ) {
  //   dispatch({ type: "CREATE_DEPTH_CHART" });
  //   // dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("test-depth-chart-click-cell")
  // ) {
  //   dispatch({
  //     type: "SET_CURRENT_DEPTH_CHART_POSITION",
  //     inputVariables: {
  //       "current depth chart position input": {
  //         "position id": state.reducer?.dummy_position_id,
  //         rank: state.reducer?.dummy_rank,
  //         "row id": state.reducer?.dummy_row_id,
  //       },
  //     },
  //   });
  //   dispatch({ type: "ADD_DEPTH_CHART_PLAYER" });
  // }
  // // if(targetElement && targetElement.classList.contains('test-depth-chart-name-search')){

  // // }
  // if (
  //   targetElement &&
  //   targetElement.classList.contains("test-depth_chart-submit-player")
  // ) {
  //   dispatch({
  //     type: "SET_DEPTH_CHART_USER",
  //     inputVariables: { Id: state.reducer?.Users?.[0]?.Id },
  //   });
  //   dispatch({
  //     type: "SET_CURRENT_DEPTH_CHART_POSITION",
  //     inputVariables: { "current depth chart position input": null },
  //   });
  // }
  // if (targetElement && targetElement.classList.contains("test-grading")) {
  //   dispatch({
  //     type: "JOYRIDE_NAVIGATION",
  //     inputVariables: { routeName: "/play_grades" },
  //   });
  // }
  // if (
  //   targetElement &&
  //   targetElement.classList.contains(
  //     "test-grading-add-new-game-or-practice"
  //   )
  // ) {
  //   console.log("testing :>> ");
  // }

  // if(data.action === "next" && data.type === 'step:after'){
  //   dispatch({ type: "CREATE_DEPTH_CHART"});
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index+1}});
  // }
  // }
  // } else if (data.lifecycle === "complete") {
  //   dispatch({ type: "TOUR_STOP" });
  // setState({ run: false, stepIndex: 0, tourActive: false });
  // }

  // else if(data.action === 'next' && data.type === 'step:after'){
  //   console.log('testing :>> ', );
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index + 1}});
  // }else if(data.action === 'next' && data.type === 'step:after'){
  //   dispatch({ type: "TOUR_START",inputVariables:{run:true,index:data?.index + 1}});
  // }

  // Check if the target element has a specific class name

  // if(targetElement && targetElement.classList.contains('test-depth-chart') ){
  //   dispatch({ type: "SET_NAVBAR_DROPDOWN_INDEX",inputVariables:{
  //     "input navbar dropdown index":
  //      state.reducer?.NavBarDropDownIndex === 0 ? -1 : 0,
  //   } });
  //   this.setState({stepIndex: data?.index + 1})
  //   // dispatch({ type: "TOUR_START"});
  // }
  // if(data.action === 'next' && data.type === 'step:after'){
  //   if(targetElement && targetElement.classList.contains('test-add-depth-chart')){
  //     dispatch({ type: "JOYRIDE_NAVIGATION",inputVariables:{routeName : '/add_depth_chart'} });
  //     // this.setState({runJoyride:true})
  //   }
  // }else if (data.action === 'reset' || data.lifecycle === 'complete') {
  //   dispatch({ type: "TOUR_STOP"});
  // }
  // };

  // const handleStepChange = (data) => {
  //   // Extract relevant information from the data object
  //   const { index, step } = data;
  //   dispatch({
  //     type: "TOUR_START",
  //     inputVariables: { run: true, index: index + 1 },
  //   });
  // };

  const handleJoyrideCallback = async (data) => {
    const { action, index, step, type } = data;
    const targetElement = document.querySelector(step.target);

    // if (action === "skip" || action === "close") {
    //   dispatch({ type: "TOUR_STOP" });
    // }

    if ((action === "next" || action === "close") && type === "step:after") {
      dispatch({
        type: "TOUR_START",
        inputVariables: { run: true, index: index + 1 },
      });
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart")
      ) {
        dispatch({
          type: "SET_NAVBAR_DROPDOWN_INDEX",
          inputVariables: {
            "input navbar dropdown index":
              state?.NavBarDropDownIndex === 0 ? 0 : 0,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-roster") &&
        type === "step:after"
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/roster" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-add-depth-chart") &&
        type === "step:after"
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/add_depth_chart" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-add-button") &&
        type === "step:after"
      ) {
        const payload = {
          query_variables: {
            year: state?.selectedSeasonYear
              ? state?.selectedSeasonYear
              : state?.SeasonYearData?.[0],
          },
        };
        const res = await GetFirstDepthChartEndpoint(payload);
        const result = res?.data;
        if (res?.data) {
          dispatch({
            type: "JOYRIDE_NAVIGATION",
            inputVariables: {
              routeName: `/depth_chart?depthchartid=${result?.id}&base=${result?.base}&year=${state?.selectedSeasonYear}`,
            },
          });
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-click-cell")
      ) {
        dispatch({
          type: "SET_CURRENT_DEPTH_CHART_POSITION",
          inputVariables: {
            "current depth chart position input": {
              "position id": state?.DepthChartUsers?.[0].positions?.[0]?.id,
              rank: state?.DepthChartUsers?.[0].positions?.[0]?.rank,
              "row id": state?.DepthChartUsers?.[0].positions?.[0]?.row_id,
            },
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth_chart-submit-player")
      ) {
        dispatch({
          type: "SET_CURRENT_DEPTH_CHART_POSITION",
          inputVariables: {
            "current depth chart position input": null,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading")
      ) {
        await dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/play_grades" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-add-new-game-or-practice"
        )
      ) {
        dispatch({
          type: "NEW_GAME",
          inputVariables: {
            "input new Game": true,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-import-play")
      ) {
        const payload = {
          query_variables: {
            year: state?.selectedSeasonYear
              ? state?.selectedSeasonYear
              : state?.SeasonYearData?.[0],
          },
        };

        const res = await GetAllGamesEndpoint(payload);
        const result = res?.data;

        if (res?.data) {
          dispatch({
            type: "JOYRIDE_NAVIGATION",
            inputVariables: {
              routeName: `/play_by_play?playgradeid=${result?.[4].Id}&year=${state?.selectedSeasonYear}`,
            },
          });
        }
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-download-playdata")
      ) {
        // dispatch({
        //   type: "TOGGLE_NEW_ROW",
        //   inputVariables: {
        //     "input new row": true,
        //   },
        // });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-new-game-or-practice-to-open"
        )
      ) {
        dispatch({
          type: "TOGGLE_NEW_ROW",
          inputVariables: {
            "input new row": false,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-add-play")
      ) {
        dispatch({
          type: "TOGGLE_NEW_ROW",
          inputVariables: {
            "input new row": false,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-upload-hudl-video")
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO" });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-paste-hudl-link")
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO", inputVariables: {} });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-bulk-edit")
      ) {
        const dropdownElements = document.getElementsByClassName(
          "form-control bulk-dropdown joyride-grading-bulk-edit"
        );
        const dropdown = dropdownElements[0];

        if (dropdown) {

          // Programmatically set the second option (Set Package)
          dropdown.value = "setpackage"; // Assuming 'setpackage' is the value of the second option

          // Trigger change event to notify any listeners of the change (if needed)
          const event = new Event("change", { bubbles: true });
          dropdown.dispatchEvent(event);
        }
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-grades")
      ) {
        dispatch({
          type: "SET_PLAY_DEPTH_CHART",
          inputVariables: {
            "toggle depth chart": true,
            "depth chart input ": state.PlayByPlayInfo[0],
            "depth chart index": state.PlayByPlayInfo[0].index,
          },
        });
        dispatch({
          type: "GET_USER_GRADES",
          inputVariables: {
            depthChartId: state.PlayByPlayInfo[0]?.["depth_chart_id"],
            playRowId: state.PlayByPlayInfo[0]?.["id"],
          },
        });
        dispatch({
          type: "GET_ALL_PLAY_DEPTH_CHART_USERS",
          inputVariables: {
            "input depth chart rowid":
              state.PlayByPlayInfo[0]?.["depth_chart_id"],
            "input play rowid": state.PlayByPlayInfo[0]?.["id"],
          },
        });
      }

      // if (
      //   targetElement &&
      //   targetElement.classList.contains("joyride-grading-save-and-close")
      // ) {
      //   dispatch({ type: "REMOVE_TOGGLEDEPTHCHART", inputVariables: {} });
      //   dispatch({
      //     type: "SET_NAVBAR_DROPDOWN_INDEX",
      //     inputVariables: {
      //       "input navbar dropdown index":
      //         state?.NavBarDropDownIndex === 2 ? -1 : 2,
      //     },
      //   });
      // }
    }

    if (action === "prev" && type === "step:after") {
      dispatch({
        type: "TOUR_START",
        inputVariables: { run: true, index: index - 1 }, // Move to the previous step
      });
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/roster" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-click-cell")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/add_depth_chart" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-name-search")
      ) {
        dispatch({
          type: "SET_CURRENT_DEPTH_CHART_POSITION",
          inputVariables: {
            "current depth chart position input": null,
          },
        });
      }
      // if (
      //   targetElement &&
      //   targetElement.classList.contains("joyride-grading")
      // ) {
      //   const payload = {
      //     query_variables: {
      //       year: state?.selectedSeasonYear
      //         ? state?.selectedSeasonYear
      //         : state?.SeasonYearData?.[0],
      //     },
      //   };
      //   const res = await GetFirstDepthChartEndpoint(payload);
      //   const result = res?.data;
      //   console.log("result", result);
      //   if (res?.data) {
      //     dispatch({
      //       type: "JOYRIDE_NAVIGATION",
      //       inputVariables: {
      //         routeName: `/depth_chart?depthchartid=${result?.id}&base=${result?.base}&year=${state?.selectedSeasonYear}`,
      //       },
      //     });
      //     await dispatch({
      //       type: "SET_CURRENT_DEPTH_CHART_POSITION",
      //       inputVariables: {
      //         "current depth chart position input": {
      //           "position id": state?.DepthChartUsers?.[0].positions?.[0]?.id,
      //           rank: state?.DepthChartUsers?.[0].positions?.[0]?.rank,
      //           "row id": state?.DepthChartUsers?.[0].positions?.[0]?.row_id,
      //         },
      //       },
      //     });
      //   }

      // }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading")
      ) {
        const payload = {
          query_variables: {
            year: state?.selectedSeasonYear || state?.SeasonYearData?.[0],
          },
        };

        GetFirstDepthChartEndpoint(payload)
          .then((res) => {
            const result = res?.data;
            if (result) {
              // First dispatch for navigation
              dispatch({
                type: "JOYRIDE_NAVIGATION",
                inputVariables: {
                  routeName: `/depth_chart?depthchartid=${result?.id}&base=${result?.base}&year=${state?.selectedSeasonYear}`,
                },
              });


                dispatch({
                  type: "SET_CURRENT_DEPTH_CHART_POSITION",
                  inputVariables: {
                    "current depth chart position input": {
                      "position id": state?.DepthChartUsers?.[0]?.positions?.[0]?.id,
                      rank:state?.DepthChartUsers?.[0]?.positions?.[0]?.rank,
                      "row id": state?.DepthChartUsers?.[0]?.positions?.[0]?.row_id,
                    },
                  },
                });
            } else {
              console.error("No result data returned from the API.");
            }
          })
          .catch((error) => {
            console.error(
              "Error during navigation or setting depth chart position:",
              error
            );
          });
      }

      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-enter-new-game-or-practice"
        )
      ) {
        dispatch({
          type: "NEW_GAME",
          inputVariables: {
            "input new Game": false,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-download-playdata")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/play_grades" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-new-game-or-practice-to-open"
        )
      ) {
        dispatch({
          type: "TOGGLE_NEW_ROW",
          inputVariables: {
            "input new row": false,
          },
        });
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-add-play")
      ) {
        // dispatch({
        //   type: "TOGGLE_NEW_ROW",
        //   inputVariables: {
        //     "input new row": true,
        //   },
        // });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-paste-hudl-link")
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO", inputVariables: null });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-assign-depth-chart-manually"
        )
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO" });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-select-set-package")
      ) {
        const dropdownElements = document.getElementsByClassName(
          "form-control bulk-dropdown joyride-grading-select-set-package"
        );
        const dropdown = dropdownElements[0];

        if (dropdown) {
          console.log("dropdown", dropdown);

          // Programmatically set the second option (Set Package)
          dropdown.value = "setpackage"; // Assuming 'setpackage' is the value of the second option

          // Trigger change event to notify any listeners of the change (if needed)
          const event = new Event("change", { bubbles: true });
          dropdown.dispatchEvent(event);

          // Add event listener for focus event (when the dropdown is opened)
          dropdown.addEventListener("focus", () => {
            console.log("Dropdown opened");
          });

          // Add event listener to detect clicks outside the dropdown to simulate "close"
          document.addEventListener(
            "click",
            function handleClickOutside(event) {
              if (!dropdown.contains(event.target)) {
                console.log("Closing dropdown by clicking outside");
                dropdown.blur(); // Trigger blur to close the dropdown

                // Remove the event listener after closing
                document.removeEventListener("click", handleClickOutside);
              }
            }
          );
        }
      }

      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-choose-position-group"
        )
      ) {
        dispatch({
          type: "REMOVE_TOGGLEDEPTHCHART",
          inputVariables: {},
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-settings")
      ) {
        dispatch({
          type: "SET_NAVBAR_DROPDOWN_INDEX",
          inputVariables: {
            "input navbar dropdown index":
              state?.NavBarDropDownIndex === 2 ? -1 : 2,
          },
        });
        dispatch({
          type: "SET_PLAY_DEPTH_CHART",
          inputVariables: {
            "toggle depth chart": true,
            "depth chart input ": state.PlayByPlayInfo[0],
            "depth chart index": state.PlayByPlayInfo[0].index,
          },
        });
        dispatch({
          type: "GET_USER_GRADES",
          inputVariables: {
            depthChartId: state.PlayByPlayInfo[0]?.["depth_chart_id"],
            playRowId: state.PlayByPlayInfo[0]?.["id"],
          },
        });
        dispatch({
          type: "GET_ALL_PLAY_DEPTH_CHART_USERS",
          inputVariables: {
            "input depth chart rowid":
              state.PlayByPlayInfo[0]?.["depth_chart_id"],
            "input play rowid": state.PlayByPlayInfo[0]?.["id"],
          },
        });
      }
    }
  };

  return (
    <Fragment>
      <Joyride
        steps={joyrideSteps}
        continuous={true}
        run={state.run}
        stepIndex={state.stepIndex}
        callback={handleJoyrideCallback}
        // showSkipButton={false}
        // showBackButton={true}
        // spotlightClicks={true}
        // hideBackButton
        // disableOverlayClose
        // scrollToSteps={false} // Disable automatic scrolling to steps
        // scrollToFirstStep // Disable automatic scrolling to the first step
        // scrollOffset={20} // Adjust scroll offset if necessary
        showProgress={true}
        // disableScrollParentFix={true} // Prevent parent elements from being scrolled
        disableScrolling={true} // Prevent automatic scrolling
        styles={{
          buttonSkip: {
            display: "block",
            position: "absolute",
            bottom: "15px",
            right: "140px",
          },
          buttonBack: {
            display: "block",
            position: "absolute",
            bottom: "15px",
            left: "15px",
          },
          buttonNext: {
            minWidth: "110px",
            maxWidth: "115px",
            padding: "10px 20px",
            whiteSpace: "nowrap",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = function (state) {
  return {
    state: state.reducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoyrideComponent);
