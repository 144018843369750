export const play_grading_table_columns = {
  "#": true,
  play: true,
  qtr: true,
  series: true,
  down: true,
  distance: true,
  type: true,
  package: true,
};

export const apiUrl = `/quickcut`;

export const qwikCutApiEndpoints = {
  authenticateUrl: "/authenticate",
  getUserTeams: `/user/{id}/teams`,
  getUserTeamsSchedule: `/user/{user_id}/team/{team_id}/schedule`,
  getUserTeamsSeasonSchedule: `/user/{user_id}/team/{team_id}/schedule/{season_id}`,
  getStatsColumns: `/stat-columns/{team_id}`,
  getGameStats: `/game/{game_id}/stats/{team_id}`,
};

const steps = [
  "Create or upload your player roster",
  "Create at least one Depth Chart",
  "Create a Game or Practice",
  "Add plays and map them to your depth chart",
  "Start grading players for each play added",
];

export const joyrideSteps = [
  {
    target: ".joyride-step",
    content: (
      <div className="joyride-content">
        <h3 className="joyride-title">Welcome to CoachTools!</h3>
        <p>There are 5 steps to getting set up:</p>
        <ol className="joyride-first-step">
          {steps.map((step, index) => (
            <li key={index} className="joyride-step-item">
              <span className="joyride-step-index">{index + 1}.</span>
              {step}
            </li>
          ))}
        </ol>
        <p>Let's start grading your players with ease!</p>
      </div>
    ),
    placement: "center",
    isFixed: true,
  },
  {
    target: ".joyride-roster",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click 'Roster'.</p>
      </div>
    ),
    isFixed: true,
  },
  {
    target: ".joyride-upload-roster",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Upload your player roster here as an Excel or CSV file, mapping player
          attributes to the CoachTools system.
        </p>
      </div>
    ),
    isFixed: true,
  },
  {
    target: ".joyride-depth-chart",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click 'Depth Charts'.</p>
      </div>
    ),
  },
  {
    target: ".sidenav .joyride-depth-chart-open .joyride-add-depth-chart",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click 'Add Depth Chart'.</p>
      </div>
    ),
  },
  {
    target: ".joyride-depth-chart-add-button",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Enter your Depth Chart info and click 'Add'.
        </p>
      </div>
    ),
    placement: "left",
  },
  {
    target: ".joyride-depth-chart-click-cell",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Click here to add players to your new Depth Chart.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-depth-chart-name-search",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Type player name to search.</p>
      </div>
    ),
  },
  {
    target: ".joyride-depth_chart-submit-player",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Submit the Position Asssignment.</p>
      </div>
    ),
  },
  {
    target: ".joyride-grading",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click 'Grading'.</p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-add-new-game-or-practice",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Click 'Add New Game or Practice' to get started with grading. Fill out
          the basic game information and click 'Create'.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-enter-new-game-or-practice",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Enter your Game or Practice info and click 'Create'.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-import-game-from-quickcut",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Alternatively, you can Import a Game from your QwikCut account.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-import-play",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Click 'Import Plays' if you have plays for your game saved in an Excel
          or CSV file. This includes game files exported from Hudl.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-download-playdata",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Click the new Game or Practice link to open it.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-add-play",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Click 'Add Play' to manually add your plays.{" "}
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-click-import-play",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Alternatively, you can Import a Plays by clicking 'Import Plays' via
          Excel or CSV if you haven't done this already.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-upload-hudl-video",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Once Plays are imported and/or created, you can Upload Hudl video for
          each play by clicking here.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-paste-hudl-link",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Paste the Hudl download link from your hudl account for the game you
          want to import video for.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-assign-depth-chart-manually",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Assign a Depth Chart to each play manually or...
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-bulk-edit",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Use Bulk Edit to quickly assign the same Depth Chart to more than one
          play.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-top-left-checkbox",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          To assign the same Depth Chart to all plays, click on the checkbox on
          the top left.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-bulk-edit",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Click the 'Bulk Edit' dropdown and 'Set Package'.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-select-set-package",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Select your depth chart by clicking 'Set Package'. This list shows
          your depth chart options and then click apply.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-apply-bulk-edit",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Click ' Apply'.</p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-apply-bulk-edit",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Reminder that you'll need to assign a Depth Chart / Package to any
          play you'd like to grade.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-grades",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">Start Grading by clicking 'Grades'.</p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-choose-position-group",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Choose the position group you'd like to grade.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-each-player-by-intangible",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Start Grading Each player by the intangible assigned to that position
          group.{" "}
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-bulk-edit-grades-checkbox",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          You can Bulk Edit grades by selecting the checkbox for one or more
          players.
        </p>
      </div>
    ),
  },
  {
    target: ".joyride-grading-click-grading-options",
    content: (
      <div className="upload-roster-content">
        <p className="joyride-text">
          Then click one of the grading options at the top. These options
          correlate to the Grading Scale Type assigned to the position group you
          are grading.
        </p>
      </div>
    ),
  },
  // {
  //   target: ".joyride-grading-individual-player-notes",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         You can also add individual player notes for each play.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-add-player",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Add a player to the play by clicking 'Add Player'
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-trash-icon",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Click the Trash Can icon to remove a player from the play.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-save-and-next",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Click Save & Next to save grades and go to the next play.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-save-and-close",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Click Save & Close once you are finished grading.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".sidenav .joyride-grading-settings-open .joyride-settings",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">Click the 'Settings'.</p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-settings-grading-scale-type",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Click 'Grading Scale Type' to edit the grading options for each
  //         position group.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-settings-intangibles",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Click 'Intangibles' to edit the Intangible options for each position
  //         group.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-settings-production-points",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         Click 'Production Points' to edit the Production Points options for
  //         each position group.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-grading-settings-school-reports",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text">
  //         After you finish grading, click 'Reports' to see customizable reports
  //         and overall player rankings.
  //       </p>
  //     </div>
  //   ),
  // },
  // {
  //   target: ".joyride-last-step",
  //   content: (
  //     <div className="upload-roster-content">
  //       <p className="joyride-text" style={{ padding: "10px" }}>
  //         You are ready to use CoachTools! If you need immediate assistance,
  //         please contact support at 484-843-4902.
  //       </p>
  //     </div>
  //   ),
  //   placement: "center",
  //   isFixed: true,
  // },
];
