import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { DivBlock, LinkBlock, List, ListItem } from "../modules/Basic";
import { Span } from "../modules/Typography";
import { superAdmin, toggleClassInbody } from "../utils/function";

class SideNavBarComponent extends Component {
  render() {
    //let { row, index, errors } = this.props;
    let { NavBarDropDownIndex, CurrentUser } = this.props;

    return (
      <Fragment>
        <DivBlock
          className={"sidenav sidenav-padding"}
          context={{
            elements: {
              1104: { type: "List" },
              1105: { type: "ListItem" },
              1106: { type: "LinkBlock" },
              1107: { type: "DivBlock" },
              1108: { type: "i" },
              1109: { type: "Span" },
              1110: { type: "ListItem" },
              1111: { type: "DivBlock" },
              1112: { type: "i" },
              1113: { type: "Span" },
              1114: { type: "Span" },
              1115: { type: "DivBlock" },
              1116: { type: "LinkBlock" },
              1117: { type: "DivBlock" },
              1118: { type: "Span" },
              1119: { type: "LinkBlock" },
              1120: { type: "DivBlock" },
              1121: { type: "Span" },
              1122: { type: "LinkBlock" },
              1123: { type: "DivBlock" },
              1124: { type: "Span" },
              1125: { type: "ListItem" },
              1126: { type: "LinkBlock" },
              1127: { type: "DivBlock" },
              1128: { type: "i" },
              1129: { type: "Span" },
              1130: { type: "ListItem" },
              1131: { type: "DivBlock" },
              1132: { type: "i" },
              1133: { type: "Span" },
              1134: { type: "Span" },
              1135: { type: "DivBlock" },
              1136: { type: "LinkBlock" },
              1137: { type: "DivBlock" },
              1138: { type: "Span" },
              1139: { type: "LinkBlock" },
              1140: { type: "DivBlock" },
              1141: { type: "Span" },
              1142: { type: "LinkBlock" },
              1143: { type: "DivBlock" },
              1144: { type: "Span" },
              1145: { type: "ListItem" },
              1146: { type: "DivBlock" },
              1147: { type: "i" },
              1148: { type: "Span" },
              1149: { type: "Span" },
              1150: { type: "DivBlock" },
              1151: { type: "LinkBlock" },
              1152: { type: "DivBlock" },
              1153: { type: "Span" },
              1154: { type: "LinkBlock" },
              1155: { type: "DivBlock" },
              1156: { type: "Span" },
              1157: { type: "ListItem" },
              1158: { type: "LinkBlock" },
              1159: { type: "DivBlock" },
              1160: { type: "i" },
              1161: { type: "Span" },
              545: { type: "Body" },
              546: { type: "DivBlock" },
              547: { type: "DivBlock" },
              558: { type: "DivBlock" },
              559: { type: "DivBlock" },
              621: { type: "DivBlock" },
              622: { type: "DivBlock" },
              623: { type: "DivBlock" },
              624: { type: "DivBlock" },
              625: { type: "DivBlock" },
              626: { type: "DivBlock" },
              627: { type: "Heading" },
              628: { type: "Span" },
              629: { type: "DivBlock" },
              630: { type: "DivBlock" },
              631: { type: "DivBlock" },
              632: { type: "DivBlock" },
              633: { type: "Span" },
              634: { type: "Span" },
              635: { type: "If" },
              642: { type: "DivBlock" },
              643: { type: "DivBlock" },
              644: { type: "Span" },
              645: { type: "Span" },
              646: { type: "If" },
              647: { type: "Then" },
              648: { type: "DivBlock" },
              649: { type: "DivBlock" },
              650: { type: "Span" },
              651: { type: "DivBlock" },
              652: { type: "DivBlock" },
              653: { type: "Span" },
              654: { type: "DivBlock" },
              655: { type: "DivBlock" },
              656: { type: "Span" },
              657: { type: "DivBlock" },
              658: { type: "Span" },
              659: { type: "table" },
              660: { type: "thead" },
              661: { type: "tr" },
              662: { type: "th" },
              663: { type: "Span" },
              664: { type: "Span" },
              665: { type: "th" },
              666: { type: "Span" },
              667: { type: "th" },
              668: { type: "Span" },
              669: { type: "th" },
              670: { type: "Span" },
              671: { type: "th" },
              672: { type: "Span" },
              673: { type: "tbody" },
              674: { type: "tr" },
              675: { type: "td" },
              676: { type: "Span" },
              677: { type: "td" },
              678: { type: "Span" },
              679: { type: "td" },
              680: { type: "Span" },
              681: { type: "td" },
              682: { type: "Span" },
              683: { type: "td" },
              684: { type: "Button" },
              685: { type: "DivBlock" },
              686: { type: "DivBlock" },
              687: { type: "DivBlock" },
              688: { type: "DivBlock" },
              689: { type: "Span" },
              690: { type: "Span" },
              691: { type: "MultipleDataGraph" },
              692: { type: "DataSeries" },
              693: { type: "If" },
            },
            getters: {},
            listeners: [
              { name: "mouseOver.boundaries" },
              { name: "mouseOut.boundaries" },
              { name: "dragEnter" },
              { name: "dragLeave" },
              { name: "dragEnd" },
              { name: "mouseDown.mousemenu" },
              { name: "showSettingsBox.desk" },
              { name: "textChanged.desk" },
              { name: "editTextMode.desk" },
              { name: "componentClick.desk" },
              { name: "pathnameChanged.desk" },
              { name: "cut.desk" },
              { name: "copy.desk" },
              { name: "create_prefab.desk" },
              { name: "create_partial.desk" },
              { name: "editSnippet.desk" },
              { name: "clone.desk" },
              { name: "delete.desk" },
              { name: "before.desk" },
              { name: "after.desk" },
              { name: "first.desk" },
              { name: "last.desk" },
              { name: "replace.desk" },
              { name: "select.desk" },
              { name: "highlight.desk" },
              { name: "dropZoneHover" },
              { name: "dragEnd" },
              { name: "dragStart" },
              { name: "copyStyleProps" },
              { name: "pasteStyleProps" },
              { name: "updateSelectedProps" },
              { name: "dragStart" },
            ],
            triggerCache: {
              componentClick: [{ name: "componentClick.desk" }],
              editSnippet: [{ name: "editSnippet.desk" }],
              mouseDown: [{ name: "mouseDown.mousemenu" }],
              mouseOut: [{ name: "mouseOut.boundaries" }],
              mouseOver: [{ name: "mouseOver.boundaries" }],
              pathnameChanged: [{ name: "pathnameChanged.desk" }],
            },
          }}
          df-snippet-instance-id={298}
          draggable={true}
          eleKey={"559"}
          inline={{ "df-snippet-instance-id": 298, draggable: true }}
          isEdit={true}
          root-snippet={true}
          style={{
            minWidth:
              CurrentUser["user_type"] === "Coach" ||
              CurrentUser["user_type"] === "Position Coach"
                ? "244.84px"
                : "205px",
          }}
        >
          <List
            df-snippet-instance-id={298}
            style={{ marginBottom: "0px", marginTop: "0px" }}
            type={"none"}
          >
            <ListItem df-snippet-instance-id={298}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={298}
                duration={1000}
                href={"/roster"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={298}
                  style={{ alignItems: "center", display: "flex" }}
                  onClick={() => toggleClassInbody("toggle-manu")}
                >
                  <i
                    className={"fa fa-user-plus side-img"}
                    df-snippet-instance-id={298}
                    style={{}}
                  ></i>

                  <Span
                    className={"side-text joyride-roster"}
                    df-snippet-instance-id={298}
                    style={{}}
                  >
                    Roster
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={298}>
              <DivBlock
                className={"a sidenav"}
                df-snippet-instance-id={298}
                actions={[
                  {
                    action: "Set Navbar Dropdown Index",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: {
                      "input navbar dropdown index":
                        NavBarDropDownIndex === 0 ? -1 : 0,
                    },
                  },
                ]}
              >
                <i
                  className={"fa fa-indent side-img"}
                  df-snippet-instance-id={298}
                  style={{}}
                ></i>

                <Span
                  className={"side-text joyride-depth-chart"}
                  df-snippet-instance-id={298}
                  style={{}}
                >
                  Depth Charts
                </Span>

                <Span
                  df-snippet-instance-id={298}
                  className={"drop_icon"}
                  style={{
                    alignItems: "flex-start",
                    color: "#afb9ce",
                    display: "flex",
                    flex: "1 0 auto",
                    fontSize: "20px",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <i
                    className="fa fa-angle-down fa-bold"
                    aria-hidden="true"
                  ></i>
                </Span>
              </DivBlock>

              <DivBlock
                className={`joyride-depth-chart-open
                  ${NavBarDropDownIndex === 0
                    ? "dropdown collapse open"
                    : "dropdown collapse"}`
                }
                df-snippet-instance-id={298}
                style={{}}
              >
                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/depth_chart_lists"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                      style={{}}
                    >
                      All Depth Charts
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/add_depth_chart"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text joyride-add-depth-chart"}
                      df-snippet-instance-id={298}
                    >
                      Add Depth Chart
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/manage-tags"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                      style={{}}
                    >
                      Manage Tags
                    </Span>
                  </DivBlock>
                </LinkBlock>
              </DivBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={298}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={298}
                duration={1000}
                href={"/play_grades"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={298}
                  style={{ alignItems: "center", display: "flex" }}
                  onClick={() => toggleClassInbody("toggle-manu")}
                >
                  <i
                    className={"fa fa-star side-img"}
                    df-snippet-instance-id={298}
                    style={{}}
                  ></i>

                  <Span className={"side-text joyride-grading"} df-snippet-instance-id={298}>
                    Grading
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={298}>
              <DivBlock
                className={"a sidenav"}
                df-snippet-instance-id={298}
                actions={[
                  {
                    action: "Set Navbar Dropdown Index",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: {
                      "input navbar dropdown index":
                        NavBarDropDownIndex === 1 ? -1 : 1,
                    },
                  },
                ]}
              >
                <i
                  className={"fa fa-trophy side-img"}
                  df-snippet-instance-id={298}
                  style={{}}
                ></i>

                <Span className={"side-text"} df-snippet-instance-id={298}>
                  Reporting
                </Span>

                <Span
                  df-snippet-instance-id={298}
                  className="drop_icon"
                  style={{
                    alignSelf: "auto",
                    color: "#afb9ce",
                    display: "flex",
                    flex: "1 0 auto",
                    fontSize: "20px",
                    height: "9px",
                    justifyContent: "flex-end",
                    width: "7px",
                  }}
                >
                  <i
                    className="fa fa-angle-down fa-bold"
                    aria-hidden="true"
                  ></i>
                </Span>
              </DivBlock>

              <DivBlock
                className={
                  NavBarDropDownIndex === 1
                    ? "dropdown collapse open"
                    : "dropdown collapse"
                }
                df-snippet-instance-id={298}
              >
                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/play_reports"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Game/Practice Reports
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/player_reports_data"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Player Reports
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/player_comparison"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Leaderboards
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/training"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Player Training Report
                    </Span>
                  </DivBlock>
                </LinkBlock>
              </DivBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={298}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={298}
                duration={1000}
                href={"/game_files"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={298}
                  style={{ alignItems: "center", display: "flex" }}
                  onClick={() => toggleClassInbody("toggle-manu")}
                >
                  <i
                    className={"fa fa-file side-img"}
                    df-snippet-instance-id={298}
                    style={{}}
                  ></i>

                  <Span className={"side-text"} df-snippet-instance-id={298}>
                    Files
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={298}>
              <DivBlock
                className={"a sidenav joyride-grading-settings-open"}
                df-snippet-instance-id={298}
                // style={{ alignItems: "center", display: "flex" }}
                actions={[
                  {
                    action: "Set Navbar Dropdown Index",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: {
                      "input navbar dropdown index":
                        NavBarDropDownIndex === 2 ? -1 : 2,
                    },
                  },
                ]}
              >
                <i
                  className={"fa fa-cog"}
                  df-snippet-instance-id={298}
                  style={{
                    marginRight: 11,
                    color: "#6a7389",
                    fontSize: "16px",
                    paddingTop: "0px",
                    paddingLeft: "10px",
                  }}
                ></i>

                <Span className={"side-text joyride-settings"} df-snippet-instance-id={298}>
                  Settings
                </Span>

                <Span
                  df-snippet-instance-id={298}
                  className="drop_icon"
                  style={{
                    alignItems: "flex-start",
                    color: "#afb9ce",
                    display: "flex",
                    flex: "1 0 auto",
                    fontSize: "20px",
                    justifyContent: "flex-end",
                  }}
                >
                  <i
                    className="fa fa-angle-down fa-bold"
                    aria-hidden="true"
                  ></i>
                </Span>
              </DivBlock>

              <DivBlock
                className={
                  NavBarDropDownIndex === 2
                    ? "dropdown collapse open"
                    : "dropdown collapse"
                }
                df-snippet-instance-id={298}
              >
                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/edit_coach"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Profile
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/coaches"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Coaches
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/grading_scales"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text joyride-grading-settings-grading-scale-type"}
                      df-snippet-instance-id={298}
                    >
                      Grading Scale Type
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/intangibles"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text joyride-grading-settings-intangibles"}
                      df-snippet-instance-id={298}
                    >
                      Intangibles
                    </Span>
                  </DivBlock>
                </LinkBlock>

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/production_points"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text joyride-grading-settings-production-points"}
                      df-snippet-instance-id={298}
                    >
                      Production Points
                    </Span>
                  </DivBlock>
                </LinkBlock>

                {superAdmin?.includes(CurrentUser["email"]) ? (
                  <LinkBlock
                    className={"a sidenav"}
                    df-snippet-instance-id={298}
                    duration={1000}
                    href={"/school_report"}
                    target={"_self"}
                    type={"spa"}
                  >
                    <DivBlock
                      className={"div-padding"}
                      df-snippet-instance-id={298}
                      onClick={() => toggleClassInbody("toggle-manu")}
                    >
                      <Span
                        className={"side-inner-text joyride-grading-settings-school-reports"}
                        df-snippet-instance-id={298}
                      >
                        School Report
                      </Span>
                    </DivBlock>
                  </LinkBlock>
                ) : null}

                <LinkBlock
                  className={"a sidenav"}
                  df-snippet-instance-id={298}
                  duration={1000}
                  href={"/exercises"}
                  target={"_self"}
                  type={"spa"}
                >
                  <DivBlock
                    className={"div-padding"}
                    df-snippet-instance-id={298}
                    onClick={() => toggleClassInbody("toggle-manu")}
                  >
                    <Span
                      className={"side-inner-text"}
                      df-snippet-instance-id={298}
                    >
                      Player Exercises
                    </Span>
                  </DivBlock>
                </LinkBlock>
              </DivBlock>
            </ListItem>

            <ListItem df-snippet-instance-id={298}>
              <LinkBlock
                className={"a sidenav"}
                df-snippet-instance-id={298}
                duration={1000}
                href={"/support"}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  df-snippet-instance-id={298}
                  className={"a sidenav"}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontSize: "16px",
                  }}
                  onClick={() => toggleClassInbody("toggle-manu")}
                >
                  <i
                    className={"fa fa-question"}
                    df-snippet-instance-id={298}
                    style={{ marginRight: 11, color: "#6a7389" }}
                  ></i>

                  <Span className={"side-text"} df-snippet-instance-id={298}>
                    Support
                  </Span>
                </DivBlock>
              </LinkBlock>
            </ListItem>
          </List>
        </DivBlock>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(SideNavBarComponent);
